<template>
	<div v-loading="loading"> 
		<p class="m-right-title">
			<span class="u-flex-1">{{info.title || ''}}</span>
			<template v-if="1">
				<!--<span>考试剩余时间</span>
			 	<span style="color: red;margin-right: 140px;">00:59:59</span> -->
				<el-button size="mini" type="primary" class="m-title_btn" @click="toback">返回</el-button>
			</template>
		</p>
		<div class="m-con"  v-for="(item,index) in list">
			<template v-if="1">
				<p class="">
					<span class="m-title-label" v-if="item.type == 1">【单选题】</span>
					<span class="m-title-label" v-if="item.type == 2">【判断题】</span>
					<span class="m-title-label" v-if="item.type == 3">【多选题】</span>
					{{index + 1}}. {{item.title}}

					
				</p>

				<div class="m-con-img" v-if="item.image">
					<img :src="item.image" />
				</div>

				<div class="u-m-t-10">
					<template v-if="item.type == 1 || item.type == 2">
						<el-radio-group v-model="item.answerList">
							<div class="u-m-t-10 max-width" v-for="(items,index) in item.options">
								<el-radio  :disabled="true"  :label="items.key">{{items.key}} .
									{{items.value}}</el-radio>
							</div>
						</el-radio-group>
					</template>
					<template v-if="item.type == 3">
						<el-checkbox-group v-model="item.answerList">
							<div class="u-m-t-10 max-width" v-for="(items,index) in item.options">
								<el-checkbox :disabled="true" :label="items.key">{{items.key}} .
									{{items.value}}</el-checkbox>
							</div>
						</el-checkbox-group>
					</template> 
				</div>

				<template v-if="1">
					<p class="u-m-t-20">
						<span class="m-font1">【您的答案】</span>
						<span class="m-font2 u-m-l-4">{{formatArr(item.answerList)}}</span>
					</p>
					<p class="u-m-t-20">
						<span class="m-font1">【正确答案】</span>
						<span class="m-font1 u-m-l-4">{{item.answer}}</span>
					</p>
					<p class="u-m-t-20">
						<span class="m-font1">【答案解析】</span>
					</p>
					<div class="u-m-t-5 u-m-l-10 m-font1" v-html="item.analysis" ></div>
				</template> 
			</template>
			<template v-else>
				<h3 style="text-align: center;font-size: 30px;">{{chengji}}分</h3>
				<p style="color: #333;text-align: center;font-weight: 600;margin-top: 10px;">最终成绩</p>
			</template>
		</div>
	</div>
</template>

<script>
	import {
		to_link
	} from "@/router/api"
	import {
		getExaminationsPaper,
		toTheTopic,
		theirPapers,
		collectQuestions
	} from "@/api/center.js"
	var oneFun = null
	export default {
		data() {
			return {
				loading: false,
				isOver: false,
				chengji: 0,
				test_record_id: '',
				info: {},
				list: [],
				currentData: {},
				current: 0,
				count: 0,
				isMaySubmit: false,

				time: 0,
				timeFun: null,
				errRecord:[],//错误题
				idArr:[],//错题id与答案
				ids:"",//错题ids
			}
		},
		mounted() {
			console.log(this.$route);
			
			var errRecord=localStorage.getItem('errRecord'); 
			 this.errRecord=JSON.parse(errRecord);
			 console.log( this.errRecord,'errRecord');
			var idArr = [];
			var ids='';
			this.errRecord.forEach(item => {  
			  idArr[item.questions_id]=item.err_answer;
			  ids+=item.questions_id+',';
			});		
				this.idArr=idArr;	
				this.ids=ids;	
						 console.log(this.idArr,'this.idArr');
			this.getInfo()
			
		},
		computed:{
			  getResultClass:function(){
				  return (item,index)=>{
					  
					  let value = this.list[this.current]
					  return item.result == true ? 'isAnswered' : 'isAnsweredError'; 
				  }
			  }
		},
		methods: {
			toback(){
				//window.history.back(-2);
				window.location.href='/center/index'
			},
			changeTheme(val) {
			       console.log(val)
			      },
			to_link,
			collect() {
				collectQuestions({
					questions_id: this.currentData.id
				}).then(res => {

				}) 
			},
			formatArr(data) {
				if (Object.prototype.toString.call(data).slice(8, -1) == 'Array') {
					return data.join(',')
				} else {
					return data
				}
			},
			//提交试卷
			async submit() { 
			},
			  
			async getInfo() {
				this.loading = true
				var that=this;
				let res = await getExaminationsPaper({
					paper_id: this.$route.query.paper_id,
					ids:this.ids
					
				})
				setTimeout(() => {
					this.loading = false
				}, 500)
				if (res.code == 200) {
					/* this.$alert('为了保证顺利考试，考试期间请勿刷新页面（导致答案丢失）', '注意事项', {
					          confirmButtonText: '确定'
							  }) */
					this.info = res.data.paper
					this.test_record_id = res.test_record_id
					let list = res.data.questions || []
					console.log(that.idArr);
					list.forEach(item => { 
						if(item.type == 3){
							var arr=that.idArr[item.id].split(',');
							item['answerList'] =arr;
						}else{
							item['answerList'] =that.idArr[item.id];
						} 
						item['result'] = ''
					})
					if (list.length > 0) {
						this.currentData = list[0]
					}
					this.count = list.length || 0
					this.list = list
					console.log(list,'list');

					let time = (res.over_time - Number(new Date()) / 1000)
					this.time = time > 0 ? time : 0
					 
				} else if (res.code === 0) {
					this.$router.back()
					this.$message.error(res.message)
				} else {
					this.$message.error(res.message)
				}
			},
		 
			 
			 
		}
	}
</script>

<style lang="scss" scoped>
	.is-disabled,.el-radio__label {
		color: #c0c4cc;
		cursor: pointer;
	}
	.el-checkbox__input,.el-checkbox__label {
		color: #c0c4cc;
		cursor: pointer;
	}
	.el-radio__input.is-disabled .el-radio__inner {
		cursor: pointer;
	}
	.el-checkbox__input.is-disabled .el-checkbox__inner {
		cursor: pointer;
	}

	.max-width{
		
	}
	.m-font1 {
		font-size: 14px;
		color: #474747;
		line-height: 14px;
	}

	.m-font2 {
		font-size: 14px;
		color: #F8A310;
		line-height: 14px;
	}

	.m-con-img {
		width: 800px;
		margin-top: 15px;

		img {
			max-width: 800px;
			height: auto;
		}
	}
	.m-title_btn{
		position: absolute;
		right: 56px;
		top: 26px;
	}

	.m-title-label {
		color: red;
	}

	.m-right-title {
		display: flex;
		position: relative;
		height: 64px;
		line-height: 20px;
		padding-top: 32px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		padding-left: 38px;
		border-bottom: 1px solid #EEEEEE;
		box-sizing: border-box;
	}

	.m-con {
		margin: 70px 44px;

		p {
			font-size: 16px;
			line-height: 24px;
			font-family: '宋体';
		}

		.m-con-font1 {
			font-size: 14px;
			color: #474747;
			line-height: 22px;
		}

		.m-btn {
			width: 110px;
			height: 33px;
			background: #FFFFFF;
			border: 1px solid #DCDCDC;
			text-align: center;
			line-height: 33px;
			font-size: 14px;
			color: #4D4D4D;
			cursor: pointer;
		}

		.m-btn2 {
			width: 87px;
			height: 33px;
			background: #E2E2E2;
			border: 1px solid #DCDCDC;
			text-align: center;
			line-height: 33px;
			font-size: 14px;
			color: #4D4D4D;
			cursor: pointer;
		}

		.m-dis {
			cursor: no-drop;
			opacity: 0.6;
		}

	}

	.m-answer {
		display: flex;
		width: 880px;
		padding: 24px 0 24px 24px;
		border: 1px solid #D2D2D2;

		.m-btn3 {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 36px;
			height: 100px;
			background: #FF8D31;
			border-radius: 3px 3px 3px 3px;
			writing-mode: vertical-lr;
			writing-mode: tb-lr;
			letter-spacing: 10px;
			color: #fff;
			font-size: 16px;
			cursor: pointer;
		}

		.m-answer-list {
			display: flex;
			flex-wrap: wrap;
			width: 830px;

			.m-answer-item {
				width: 33px;
				height: 33px;
				background: #F1F1F1;
				border: 1px solid #D2D2D2;
				border-radius: 3px;
				font-size: 15px;
				color: #D6102A;
				text-align: center;
				line-height: 33px;
				cursor: pointer;
				margin-right: 6px;
				margin-bottom: 10px;
			}
			.isAnswered{
				  background: #42acdc;
				  color: #fff;
				  border: 0;
			}
			.isAnsweredError{
				  background: red;
				  color: #fff;
				  border: 0;
			}
		}
	}
</style>
<style lang="scss">
	.m-con {
		.el-radio, .el-radio__input{
			white-space:pre-wrap!important;
		}
		.el-radio__input.is-checked .el-radio__inner {
			border-color: #F8A310;
			background: #F8A310;
		}

		.el-radio__label {
			font-size: 16px;
			font-family: '宋体';
			line-height: 24px;
			cursor: pointer !important;
		}

		.el-radio__input.is-checked+.el-radio__label {
			color: #F8A310;
		}

		.el-checkbox__input.is-checked+.el-checkbox__label {
			color: #F8A310;
		}

		.el-checkbox__input.is-checked .el-checkbox__inner,
		.el-checkbox__input.is-indeterminate .el-checkbox__inner {
			background-color: #F8A310;
			border-color: #F8A310;
		}
	}
</style>
